<script setup lang="ts">
import { useGetActiveDeals } from '../core/application';
import { getDealsSections } from '../core/domain';
import { useActiveDeals } from '../infrastructure/store';
import ActiveDealsSection from './ActiveSection.vue';

const getActiveDeals = useGetActiveDeals();

const activeDeals = useActiveDeals();
const activeDealsSections = computed(() => getDealsSections(activeDeals.value));

getActiveDeals();
</script>

<template>
	<div
		v-if="activeDeals.length"
		class="flex flex-col gap-[14px]"
	>
		<ActiveDealsSection
			v-for="activeSection in activeDealsSections"
			:key="activeSection.asset + activeSection.stringifiedTime"
			:section="activeSection"
		/>
	</div>
</template>
